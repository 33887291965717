import React, { useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";

export default function HomeHyperlinksSelect(props) {
    const hyperlinkRef = useRef(null);
    const [tellCaller, setTellCaller] = useState("");
    useEffect(() => {
        try {
            hyperlinkRef.current.innerHTML = tellCaller;
        }
        catch (e) { console.log(e); }
    }, [props.q.AnswerId, tellCaller]);


    return (
        <>
            <div>
                <Input
                    type="select"
                    valid={props.q.AnswerId && props.q.AnswerId > 0}
                    onChange={(e) => {
                        props.q.AnswerId = e.target.value;

                        var index = e.target.selectedIndex;
                        props.q.AnswerValue = e.target[index].text;

                        let tellCaller = props.q.answers.find(s => s.AnswerId.toString() === e.target.value.toString());
                        if (tellCaller) {
                            props.q.TellCaller = tellCaller.TellCaller;

                            let TellCaller = props.q.TellCaller;
                            let hyperlinks = TellCaller.match(/((http:\/\/|https:\/\/|www.|\/\/|file:|ftp:)[^\s]+)/gi);

                            if (hyperlinks) {
                                hyperlinks.forEach(h => {
                                    TellCaller = TellCaller.replace(h, `<a href="${h}" target="_blank">${h}</a>`);
                                });
                            }
                            setTellCaller(TellCaller);
                        }
                    }} >
                    <option key="0" value="" ></option>
                    {props.q.answers.map((a) => (
                        <option key={a.AnswerId} id={"answerid" + a.AnswerId} value={a.AnswerId} >
                            {a.Answer}
                        </option>
                    ))}

                </Input>

                <span ref={hyperlinkRef} className="qa-label" ></span>

            </div>
        </>)
};