import React, { useState, useEffect } from "react";
import "./App.css";
import Home from "./home/home";
import StatusDetails from "./status/status-details";
import { UncontrolledAlert } from "reactstrap";
import { LOCALES } from "./i18n";
import $ from "jquery";

export default function App() {
  const [refresh, setRefresh] = useState(0);
  const [showVersion, setShowVersion] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [hideMapByTemplateKeyword, setHideMapByTemplateKeyword] = useState("");
  const [webMapId, setWebMapId] = useState("");
  const [webMapUrl, setWebMapUrl] = useState("");
  const [version, setVersion] = useState("");
  const [mapLoaded, setmapLoaded] = useState(false);
  const [alerts, setAlert] = useState([]);
  const [messageTimeout, setMessageTimeout] = useState(5000);

  const [state, setState] = useState({});
  const [lang, setLang] = useState(LOCALES.EHGLISH);
  const [en, setEN] = useState({});
  const [fr, setFR] = useState({});

  const [useTitle, setuseTitle] = useState(false);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("");
  const [usecaptcha, setUsecaptcha] = useState(false);
  const [useTranslation, setUseTranslation] = useState(false);
  const [useFontOptions, setUseFontOptions] = useState(false);
  const [useMapGraphics, setUseMapGraphics] = useState(false);
  const [availableCreateTools, setAvailableCreateTools] = useState("");
  const [useMapMeasurement, setUseMapMeasurement] = useState(false);
  const [drawingRequiredForAllTypes, setDrawingRequiredForAllTypes] =
    useState(false);
  const [
    drawingRequiredForTemplateKeyword,
    setDrawingRequiredForTemplateKeyword,
  ] = useState("");
  const [
    drawingRequiredForTemplateKeywordFound,
    setDrawingRequiredForTemplateKeywordFound,
  ] = useState(false);
  const [markUpSelected, setMarkUpSelected] = useState([]);

  const [imageList, setImageList] = useState([]);
  const [homeImageMap, setHomeImageMap] = useState([]);
  const [fullAttachList, setFullAttachList] = useState([]);

  const [initiatedByApp, setInitiatedByApp] = useState(false);

  const [problemTypes, setProblemTypes] = useState([]);
  const [problemTypesCommon, setProblemTypesCommon] = useState([]);

  const [isHome, setIsHome] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [token, setToken] = useState("");
  const [extent, setExtent] = useState({});
  const [sp, setSp] = useState(-1);

  //==========
  const [geomUrl, setGeomUrl] = useState("");
  const [geomToken, setGeomToken] = useState("");
  const [gcoderUrl, setGeoCoderUrl] = useState("");
  const [gcoderToken, setGeoCoderToken] = useState("");
  const [addressField, setAddressField] = useState("");

  const [geomTokenRequired, setGeomTokenRequired] = useState(false);
  const [gcoderTokenRequired, setGcoderTokenRequired] = useState(false);
  const [fontSize, setFontSize] = useState(16);

  const [limitFilesAttach, setLimitFilesAttach] = useState("");
  const [maxFileSize, setMaxFileSize] = useState(-1);

  const [openProblemsList, setOpenProblemsList] = useState(false);
  const [userSetId, setUserSetId] = useState("");
  const [FeatureServiceUrl, setFeatureServiceUrl] = useState("");
  const [geoCodeZoomLevel, setGeoCodeZoomLevel] = useState(13);
  const [isCw23Plus, setIsCw23Plus] = useState(false);
  const [hideStatusQueryButton, setHideStatusQueryButton] = useState(false);
  const [hideAttachmentPanel, setHideAttachmentPanel] = useState(false);
  const [basemapDefaultUrl, setBasemapDefaultUrl] = useState('');

  useEffect(() => {
    loadDiv("show");
    getConfig(userSettingsId());
  }, [imageList]);

  const updateFullAttachList = (images) => {
    setFullAttachList(images);
  }

  const updateImageList = (images) => {
    setImageList(images);
  }

  const updateHomeImageMap = (images) => {
    setHomeImageMap(images);
  }

  const userSettingsId = () => {
    let pathArray = window.location.pathname.split("/");

    if (pathArray.length == 0) {
      setUserSetId(" ");
      return " ";
    } else {
      let u = pathArray[pathArray.length - 1];
      setUserSetId(u);
      return u;
    }
  };

  const Guid = () => {
    return (
      "uuid-" +
      (new Date().getTime().toString(16) +
        Math.floor(1e7 * Math.random()).toString(16))
    );
  };

  const setAlerts = (msg, type, clear = true) => {
    if (clear) clearAlerts();
    if (msg) {
      setAlert([{ guid: Guid(), info: msg, color: type }]);
      window.setTimeout(() => {
        clearAlerts();
      }, messageTimeout);
    }
  };

  const clearAlerts = () => {
    setAlert([]);
  };

  const updateMapLoad = (option = false) => {
    setmapLoaded(option);
    if (option) document.getElementById("overlay").style.display = "none";
  };
  const loadDiv = (option, txt) => {
    if (option != "hide") {
      //block/none
      if (mapLoaded && mapLoaded === true) {
        if (document.getElementById("overlay")) document.getElementById("overlay").style.display =
          option === "show" ? "block" : "none";
      }
      else { if (document.getElementById("overlay")) document.getElementById("overlay").style.display = "block"; }
    } else {
      if (document.getElementById("overlay")) document.getElementById("overlay").style.display = "none";
    }
    if (document.getElementById("loadmsg")) document.getElementById("loadmsg").innerHTML = txt ? txt : "Loading...";
  };

  const changeLang = (ev) => {
    let evV = ev === "en" ? LOCALES.FRENCH : LOCALES.EHGLISH;
    setLang(ev === "en" ? LOCALES.FRENCH : LOCALES.EHGLISH);
    if (document.getElementById("sr_lang")) document.getElementById("sr_lang").setAttribute("langVal", evV);

    try {
      document.title = translate(evV, "pageTitleLabel");

      $(".esri-locate, .esri-icon-locate").prop(
        "title",
        translate(evV, "MapFindMyLocation")
      );

      $("#pinGeocoder").prop(
        "title",
        translate(evV, "BtnMapGeocoder")
      );

      Array.from(
        document.getElementsByClassName("esri-widget--button")
      ).forEach((el) => {
        let labelValue = $(el).find(".esri-icon-font-fallback-text").text();
        if (labelValue.toString() == "Zoom in") {
          $(el).prop("title", translate(evV, "MapZoomIn"));
        }
        if (labelValue.toString() == "Zoom out") {
          $(el).prop("title", translate(evV, "MapZoomOut"));
        }
      });

      if (document.getElementById("BtnUnselectGraphics")) {
        document
          .getElementById("BtnUnselectGraphics")
          .setAttribute("lang", evV);
        document.getElementById("BtnUnselectGraphics").innerText = translate(
          evV,
          document.getElementById("BtnUnselectGraphics").getAttribute("name")
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changePage = (ev) => {
    loadDiv("hide");
    setIsHome(ev ? false : true);
    clearAlerts();
  };

  const showHome = () => {
    setIsHome(false);
    state.problemtype.valueDesc = "";
    state.problemLocation.valueDesc = "";

    setIsHome(true);
  };

  const translate = (_lang, name) => {
    let result = "";
    try {
      if (_lang === "en") {
        result = en[name] ? en[name] : name;
      } else {
        result = fr[name] ? fr[name] : name;
      }
    } catch {
      result = name;
    }
    return result;
  };

  const getProblems = (t, userSetId, isCw23Plus, version) => {
    try {
      window
        .fetch(`GetProblems?token=${t}&userSettingsId=${userSetId}&isCw23Plus=${isCw23Plus}&version=${version}`)
        .then((resp) => {
          return resp.json();
        })
        .catch((error) => { console.log(error) })
        .then((data) => {
          loadDiv("hide");
          if (data.status === 0) {
            setProblemTypesCommon(data.valueCommon);
            setProblemTypes(data.value);
          } else {
            setAlerts(data.message, "danger");
          }
        }).catch((error) => { console.log(error) });
    } catch (error) {
      loadDiv("hide");
      console.log("Error: ", error);
      setAlert(error, "danger");
    }
  };

  const getConfig = (userSettingsId) => {
    try {
      let currentTime = new Date().toString();
      window
        .fetch(`GetConfig?userSettingsId=${userSettingsId}&date=${currentTime}`)
        .then((resp) => {
          return resp.json();
        }).catch((error) => { console.log(error) })
        .then((data) => {
          if (data.status === 0) {
            let trans = JSON.parse(data.translation);
            setEN(trans["en"]);
            setFR(trans["fr"]);
            setuseTitle(data.useTitle);
            setInitiatedByApp(data.initiatedByApp);
            setUseTranslation(data.useTranslation);
            setUseFontOptions(data.useFontOptions);
            setUsecaptcha(data.usecaptcha);
            setUseMapGraphics(data.useMapGraphics);
            setAvailableCreateTools(data.availableCreateTools);
            setUseMapMeasurement(data.useMapMeasurement);
            setDrawingRequiredForAllTypes(data.drawingRequiredForAllTypes);
            setDrawingRequiredForTemplateKeyword(
              data.drawingRequiredForTemplateKeyword
            );

            setRecaptchaSiteKey(data.recaptchaSiteKey);
            setToken(data.token);
            setExtent(data.extent);
            setSp(data.sp);
            setGeomUrl(data.geomurl);
            setGeomToken(data.geomt);
            setGeoCoderUrl(data.gcodeurl);
            setGeoCoderToken(data.gcodet);
            setAddressField(data.geoCoderAddressField);

            setGeomTokenRequired(data.geoms);
            setGcoderTokenRequired(data.gcodes);

            setLimitFilesAttach(data.limitFilesAttach);
            setMaxFileSize(data.maxFileSize);
            setGeoCodeZoomLevel(data.geoCodeZoomLevel);
            setOpenProblemsList(data.openProblemsList);

            setFeatureServiceUrl(data.featureServiceUrl);
            setMarkUpSelected([]);
            setVersion(data.version);
            setShowMap(data.showMap);
            setHideMapByTemplateKeyword(data.hideMapByTemplateKeyword);

            setWebMapId(data.webMapId);
            setWebMapUrl(data.webMapUrl);
            setIsCw23Plus(data.isCw23Plus);
            setHideStatusQueryButton(data.hideStatusQueryButton);
            setHideAttachmentPanel(data.hideAttachmentPanel);
            setBasemapDefaultUrl(data.basemapDefaultUrl);


            getProblems(
              data.token,
              data.userSettingsId === null ? "" : data.userSettingsId,
              data.isCw23Plus,
              data.version
            );

            let values = data.value;

            let nValues = {};
            Object.values(values).forEach((v) => {
              let o = { ...v, value: "", status: -1 };
              if (o.name === "problemLocation")
                o = { ...v, value: "", valueDesc: "", status: -1 }; //problemLocation.valueDesc
              nValues[o.name] = o;
            });
            nValues["maprefresh"] = {
              name: "maprefresh",
              value: "-1",
              extent: {},
            };
            nValues["imageList"] = [];
            //maprefresh
            nValues["x"] = { name: "x", value: null };
            nValues["y"] = { name: "y", value: null };

            setState(nValues);

            setIsStart(true);

            setLang(data.language);
            setMessageTimeout(data.messageTimeout);
          } else {
            setAlerts(data.message, "danger");
            loadDiv("hide");
          }
        }).catch((error) => { console.log(error) });
    } catch (error) {
      loadDiv("hide");
      console.log("Error: ", error);
      setAlert(error, "danger");
    }
  };

  const updateState = (_state) => {
    let newstate = { ...state };
    newstate.imageList = [];
    setState(newstate);
    setRefresh(refresh + 1);
  }

  const setupMarkUpSelected = (graphics) => {
    setMarkUpSelected(graphics);
  };

  const changeFont = (size) => {
    setFontSize(size);
    document.getElementById("appFont").style.fontSize = `${size}px`;
  };

  return (
    <>
      <div id="appFont">
        {/* <I18nProvider locale={lang}>*/}
        {/* {t('language')} 
            {t('edit', {path:<span>test</span>})} */}
        <div id="overlay">
          <span className="loadMessage" id="loadmsg"></span>
        </div>

        <div className="my-alert" style={{ position: "fixed" }}>
          {alerts.map((e) => (
            <UncontrolledAlert color={e.color} key={e.guid}>
              {e.info}
            </UncontrolledAlert>
          ))}
        </div>

        <>
          {isStart && isHome && problemTypes && problemTypes.length > 0 ? (
            <>
              <div id="core_state"></div>
              <Home
                version={version}
                showMap={showMap}
                hideMapByTemplateKeyword={hideMapByTemplateKeyword}
                loadDiv={loadDiv}
                lang={lang}
                state={state}
                en={en}
                fr={fr}
                useTitle={useTitle}
                usecaptcha={usecaptcha}
                useTranslation={useTranslation}
                useFontOptions={useFontOptions}
                useMapGraphics={useMapGraphics}
                availableCreateTools={availableCreateTools}
                useMapMeasurement={useMapMeasurement}
                drawingRequiredForAllTypes={drawingRequiredForAllTypes}
                drawingRequiredForTemplateKeyword={
                  drawingRequiredForTemplateKeyword
                }
                drawingRequiredForTemplateKeywordFound={
                  drawingRequiredForTemplateKeywordFound
                }
                markUpSelected={markUpSelected}
                setupMarkUpSelected={setupMarkUpSelected}
                initiatedByApp={initiatedByApp}
                changeLang={changeLang}
                isHome={isHome}
                changePage={changePage}
                showHome={showHome}
                translate={translate}
                getConfig={getConfig}
                problemTypes={problemTypes}
                recaptchaSiteKey={recaptchaSiteKey}
                setAlerts={setAlerts}
                clearAlerts={clearAlerts}
                Guid={Guid}
                problemTypesCommon={problemTypesCommon}
                token={token}
                extent={extent}
                sp={sp}
                geomUrl={geomUrl}
                geomToken={geomToken}
                gcoderUrl={gcoderUrl}
                gcoderToken={gcoderToken}
                addressField={addressField}
                geomTokenRequired={geomTokenRequired}
                gcoderTokenRequired={gcoderTokenRequired}
                fontSize={fontSize}
                changeFont={changeFont}
                limitFilesAttach={limitFilesAttach}
                maxFileSize={maxFileSize}
                openProblemsList={openProblemsList}
                FeatureServiceUrl={FeatureServiceUrl}
                userSetId={userSetId}
                isCw23Plus={isCw23Plus}

                updateMapLoad={updateMapLoad}
                webMapId={webMapId}
                webMapUrl={webMapUrl}
                setShowVersion={setShowVersion}
                showVersion={showVersion}
                updateState={updateState}
                refresh={refresh}
                geoCodeZoomLevel={geoCodeZoomLevel}
                hideStatusQueryButton={hideStatusQueryButton}
                hideAttachmentPanel={hideAttachmentPanel}
                basemapDefaultUrl={basemapDefaultUrl}
              />
            </>
          ) : (
            ""
          )}

          {!isHome ? (
            <StatusDetails
              loadDiv={loadDiv}
              lang={lang}
              state={state}
              en={en}
              fr={fr}
              useTitle={useTitle}
              usecaptcha={usecaptcha}
              useTranslation={useTranslation}
              initiatedByApp={initiatedByApp}
              isHome={isHome}
              changePage={changePage}
              translate={translate}
              setAlerts={setAlerts}
              clearAlerts={clearAlerts}
              Guid={Guid}
              token={token}
              userSetId={userSetId}
              isCw23Plus={isCw23Plus}
              hideStatusQueryButton={hideStatusQueryButton}
              hideAttachmentPanel={hideAttachmentPanel}
            />
          ) : (
            ""
          )}
        </>
        {/* </I18nProvider>*/}
      </div>

    </>
  );
}
