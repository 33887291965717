import React, { useEffect } from "react";
import { FormGroup, Input, FormFeedback, Label } from "reactstrap";
import PopoverItem from "../common/PRpopover";

const PRInput = (props) => {

  
    useEffect(() => {
        if (props.elem.name && (props.elem.name === "additionalDetail" || props.elem.name === "problemLocation" || props.elem.name === "locationDetails") ) {
           // console.log("props.elem.name");
            try {
                let el = document.getElementById(props.elem.name);

                //el.textContent = props.elem.value;

                el.classList.remove("is-valid");
                el.classList.remove("is-invalid");

                if (props.elem.value && props.elem.value.length > 0) {
                    el.classList.add("is-valid");
                } else {
                    if (props.elem.required) el.classList.add("is-invalid");
                }
            } catch (ex) { console.log(ex); }
        }
    }, [props.elem.required, props.elem.value]);
  return (
    <>
      {props.elem.disable ? (
        ""
      ) : (
        <FormGroup>
          <Label className={props.elem.fontWeight ? "label-font" : ""}>
                          {props.translate(props.lang, props.elem.label)}{" "}
                         
                          {props.elem.required ? " " :
                              (props.elem.name != "SRnumber" && props.elem.name != "SRRstatus" && props.elem.name != "DateInvtDone")?
                              <span className="colorred">{props.translate(
                    props.lang,"optionalSign")}</span>:"" }
          </Label>
          <PopoverItem elem={props.elem} translate={props.translate} lang={props.lang}/>
    

                      {(props.elem.name !== "additionalDetail" && props.elem.name !== "problemLocation" && props.elem.name !== "locationDetails")?

                      <Input 
                          key={props.elem.name}
                        
                          type={(props.elem.name === "email") ? "email" : ((props.elem.name !== "additionalDetail" && props.elem.name !== "problemLocation" && props.elem.name !== "locationDetails") ? "text" : "textarea")}
                          //autoComplete="off"
                          disabled={props.disabled ? "disabled" : ""}
                          name={props.elem.name}
                          id={props.elem.name}
                          placeholder={props.placeholder ? ((props.translate(props.lang, props.placeholder) !== props.elem.name + "Placeholder") ? props.translate(props.lang, props.placeholder) : "") : ""}
                          value={(props.elem.value) ? props.elem.value : ""}
                              valid={((props.elem.name != "SRnumber" && props.elem.name != "SRRstatus" && props.elem.name != "DateInvtDone" && props.elem.name !== "SRlastName") && (
                                  (props.elem.required && props.elem.value && props.elem.value.length > 0 && props.elem.status == 0) ||
                                  (!props.elem.required && ((props.elem.status == 0)||( !props.elem.value))    ))) }
                              invalid={
                                  (props.elem.name != "SRnumber" && props.elem.name != "SRRstatus" && props.elem.name != "DateInvtDone" && props.elem.name !== "SRlastName") &&
                                  !((props.elem.name != "SRnumber" && props.elem.name != "SRRstatus" && props.elem.name != "DateInvtDone" && props.elem.name !== "SRlastName") && (
                                  (props.elem.required && props.elem.value && props.elem.value.length > 0 && props.elem.status == 0) ||
                                  (!props.elem.required && ((props.elem.status == 0)||( !props.elem.value))  )))}
                   

                          onClick={(e) => {

                              props.validate(e);
                          }}
                          onKeyUp={(e) => {

                              props.validate(e);
                          }}
                          onChange={(e) => {
                              props.validate(e);
                          }}
                          />
                          :
                          <span className="form-control textareaEdit" role="textbox" contentEditable 
                          key={props.elem.name}

                          name={props.elem.name}
                              id={props.elem.name}
                           
                          data-placeholder={props.placeholder ? ((props.translate(props.lang, props.placeholder) !== props.elem.name + "Placeholder") ? props.translate(props.lang, props.placeholder) : "") : ""}
                            
                              onInput={(e) => {
                                  try { e.currentTarget.classList.remove("is-valid"); } catch { }
                                  try { e.currentTarget.classList.remove("is-invalid"); } catch { }
                                  try {
                                      if (e.currentTarget.textContent && e.currentTarget.textContent.length > 0) {
                                          e.currentTarget.classList.add("is-valid");

                                      } else {

                                          if (props.elem.required) e.currentTarget.classList.add("is-invalid");

                                      }
                                      if (e && e.currentTarget && e.currentTarget.textContent) {
                                          props.elem.value = e.currentTarget.textContent;
                                      }
                                          props.validateSpan(e, props.elem.name);
                                      
                                  } catch { }
                              }}
                            
                          ></span>
                          
                          
                          }
                      <FormFeedback>
                          {props.elem.required && (!props.elem.value || props.elem.value === "" )
                              ?
           
                              (props.elem.errorlabel && props.elem.errorlabel.length > 0 ? props.translate(props.lang, props.elem.errorlabel)
                                  : props.translate(props.lang, props.elem.label).replace(':', '') + " " + props.translate(props.lang, "isRequired") + '. ')
              : ""}
          </FormFeedback>
        </FormGroup>
      )}
    </>
  );
};

export default PRInput;

// PRInput.propTypes = {
//   onChange: PropTypes.func.isRequired,
// };
