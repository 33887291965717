import React, { useEffect } from "react";
import { Form, Col, Button, FormGroup, Table } from "reactstrap";
import StatusFormTableTd from "./status-form-table-td";
import PRInput from "../common/PRInput";

export default function StatusFormResult(props) {
  let disabledVal = true;
  props.state.SRnumber.required = false;
  props.state.SRRstatus.required = false;
  props.state.DateInvtDone.required = false;
  props.state.SRnumber.status = 0;
  props.state.SRRstatus.status = 0;
  props.state.DateInvtDone.status = 0;

  useEffect(() => {

    //rops.elem.required
  }, []);

  return (
    <>
      <Form
        onSubmit={(evt) => {
          evt.preventDefault();
          props.clickNewSearch(evt);
        }}
      >
        <Col>
          <PRInput
            elem={props.state.SRnumber}
            validate={props.onchange}
            translate={props.translate}
            lang={props.lang}
            disabled={disabledVal}
          />

          <PRInput
            elem={props.state.SRRstatus}
            validate={props.onchange}
            translate={props.translate}
            lang={props.lang}
            disabled={disabledVal}
          />
          <PRInput
            elem={props.state.DateInvtDone}
            validate={props.onchange}
            translate={props.translate}
            lang={props.lang}
            disabled={disabledVal}
          />

          {props.state.comments.length ? (
            <Table responsive>
              <thead>
                <tr>
                  Comments
                </tr>
              </thead>
              <tbody>
                {props.state.comments.map((a) => (
                  (a.activityType == 1) ?
                    <tr key={props.Guid()}>
                      <td key={props.Guid()}>{a.comments}</td>
                    </tr> : ''
                ))}
              </tbody>
            </Table>)
            : ""}
          {(props.state.values.length) ? (props.translate(props.lang, "WorkOrderInfoLabel")) : ""}

          {props.state.values.length ? (

            <Table responsive>
              <thead>
                <tr>
                  {props.state.header.map((a) => (
                    (!a.disable) ?
                      <th key={props.Guid()}>{props.translate(props.lang, a.label)}</th> : ''
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.state.values.map((a) => (
                  <tr key={props.Guid()}>
                    <StatusFormTableTd value={a} Guid={props.Guid} />
                  </tr>
                ))}
              </tbody>
            </Table>) : ""}


          {props.state.comments.length ? (
            <Table responsive>
              <thead>
                <tr>
                  Comments
                </tr>
              </thead>
              <tbody>
                {props.state.comments.map((a) => (
                  (a.activityType == 2) ?
                    <tr key={props.Guid()}>
                      <td key={props.Guid()}>{a.comments}</td>
                    </tr> : ''
                ))}
              </tbody>
            </Table>)
            : ""}


          <FormGroup>
            <Button
              color="info"
              onClick={(e) => {
                props.clickNewSearch(e);
              }}
            >
              {props.translate(props.lang, "SRbuttonNewSearchLabel")}
            </Button>
          </FormGroup>
        </Col>
      </Form>
    </>
  );
}
