import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import StatusForm from "./status-form";
import StatusFormResult from "./status-form-result";
import { sampleState } from "../common/defaultState";

export default function StatusDetails(props) {
  const [fstate, setfState] = useState(sampleState);
  const [showSearch, setShowSearch] = useState(true);
  const [searchResult, setSearchResult] = useState({});
  const [SRRwoActualStartDate, setSRRwoActualStartDate] = useState(true);
  const [SRRwoActualFinishDate, setSRRwoActualFinishDate] = useState(true);

  useEffect(() => {
    let nValues = {};
    Object.values(props.state).forEach((v) => {
      let o = {
        name: v.name,
        label: v.label,
        required: v.required,
        disable: v.disable,
        errorlabel: v.errorlabel,
        fontWeight: v.fontWeight,
        usepattern: v.usepattern,
        value: "",
        status: -1,
      };
      if (v.name === "SRRwoActualStartDate") setSRRwoActualStartDate(v.disable);
      if (v.name === "SRRwoActualFinishDate")
        setSRRwoActualFinishDate(v.disable);

      nValues[v.name] = o;
    });

    setfState(nValues);
  }, [props.state]);

  const onchange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setfState({
      ...fstate,
      [e.target.name]: { ...fstate[e.target.name], value },
    });
  };

  const clickNewSearch = (evt) => {
    evt.preventDefault();
    setShowSearch(true);
  };

  const clickSearch = (evt) => {
    evt.preventDefault();
    props.clearAlerts();
    props.loadDiv("show", "In process...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        SRnumber: fstate.SRnumber.value,
        SRlastName: fstate.SRlastName.value,
        token: props.token,
        userSettingsId: props.userSetId,
        isCw23Plus: props.isCw23Plus
      }),
    };

    window
      .fetch(`CheckStatus?version=${props.version}`, requestOptions)
      .then((resp) => {
        return resp.json();
      })
      .catch((error) => { console.log(error) })
      .then((data) => {
        try {
          if (data.status === 0) {
            setfState({
              ...fstate,
              [fstate.SRRstatus.name]: {
                ...fstate[fstate.SRRstatus.name],
                value: data.srstatus,
              },
              [fstate.SRRwodescription.name]: {
                ...fstate[fstate.SRRwodescription.name],
                value: data.description,
              },
              [fstate.SRRwostatus.name]: {
                ...fstate[fstate.SRRwostatus.name],
                value: data.wostatus,
              },
              //[fstate.SRRwoActualStartDate.name]: {
              //  ...fstate[fstate.SRRwoActualStartDate.name],
              //  value: data.actualstartdate,
              //},
              [fstate.SRRwoActualFinishDate.name]: {
                ...fstate[fstate.SRRwoActualFinishDate.name],
                value: data.actualfinishdate,
              },
            });

            let wdata = [];

            data.value.forEach((a) => {
              wdata.push([
                { value: a.workorderid, disable: false },
                { value: a.description, disable: false },
                { value: a.initiatedate, disable: SRRwoActualStartDate },
                { value: a.wostatus, disable: false },
                //{ value: a.actualstartdate },
                { value: a.actualfinishdate, disable: SRRwoActualFinishDate },
              ]);
            });
           // console.log("data=>",data);
            let _searchResult = {
              comments:data.comments,
              header: [
                { label: "ID", disable: false },
                { label: fstate.SRRwodescription.label, disable: false },
                { label: "Initiated Date", disable: SRRwoActualStartDate },
                { label: fstate.SRRwostatus.label, disable: false },
                //{ label: fstate.SRRwoActualStartDate.label },
                {
                  label: fstate.SRRwoActualFinishDate.label,
                  disable: SRRwoActualFinishDate,
                },
              ],
              values: wdata,
              SRnumber: {
                name: "SRnumber",
                value: fstate.SRnumber.value,
                label: fstate.SRnumber.label,
                disable: fstate.SRnumber.disable,
              },
              SRRstatus: {
                name: "SRRstatus",
                value: data.srstatus,
                label: fstate.SRRstatus.label,
                disable: fstate.SRRstatus.disable,
              },
              DateInvtDone: {
                name: "DateInvtDone",
                value: data.dateInvtDone,
                label: fstate.DateInvtDone.label,
                disable: fstate.DateInvtDone.disable,
              },
            };

            setSearchResult(_searchResult);

            setShowSearch(false);
            props.loadDiv("hide");
          } else {
            props.setAlerts(data.message, "danger");
            props.loadDiv("hide");
          }
        } catch (error) {
          props.loadDiv("hide");
          console.log("Error: ", error);
          props.setAlert(error, "danger");
        }
      })
      .catch((error) => { console.log(error) });
  };

  return (
    <div className="maxElement">
      <div className="text-right">
        <Button
          color="info"
          onClick={(e) => {
            props.changePage(props.isHome);
          }}
        >
          {props.translate(props.lang, "BackhyperlinkLabel")}
        </Button>
      </div>

      {showSearch && fstate ? (
        <StatusForm
          state={fstate}
          lang={props.lang}
          translate={props.translate}
          onchange={onchange}
          clickSearch={clickSearch}
        />
      ) : (
        ""
      )}

      {!showSearch && fstate ? (
        <StatusFormResult
          state={searchResult}
          lang={props.lang}
          translate={props.translate}
          clickNewSearch={clickNewSearch}
          Guid={props.Guid}
        />
      ) : (
        ""
      )}
    </div>
  );
}
