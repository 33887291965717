import React, { useEffect, useRef } from "react";
import { Label, Input, FormGroup, Button, Card } from "reactstrap";
import HomeHyperlinks from "./home-hyperlinks";

import HomeHyperlinksSelect from "./home-qa-linear-select";


export default function HomeQAL(props) {
    const hyperlinkRef = useRef(null);
    useEffect(() => {
    }, [props.allQ]);



    return (
        <>


            {props.allQ && props.allQ.length > 0 ? (
                <div>
                    {props.allQ.map((q) => (
                        <Card className="QALCard" key={"card" + q.QuestionId}>
                            <FormGroup key={q.QuestionId}>
                                <Label className="LbBold"> {props.translate(props.lang, "Question")} {q.QuestionSequence}:</Label>{" "}
                                <p>  <span disabled className="textarea" id="question" role="textbox" >{q.Question}</span></p>

                                {q.answers ? (

                                    <FormGroup>
                                        <Label className="LbBold">{props.translate(props.lang, "Answer")} {q.QuestionSequence}:</Label>
                                        {q && q.answers && q.answers[0] && q.answers[0].AnswerFormat && q.answers[0].AnswerFormat !== "FREETEXT" &&
                                            q.answers[0].AnswerFormat !== "DATE" &&

                                            q.answers ? (

                                            <HomeHyperlinksSelect q={q}  
                                             id={q.QuestionId}></HomeHyperlinksSelect>
                                        ) : (
                                            ""
                                        )}

                                        {(q && q.answers && q.answers[0] && q.answers[0].AnswerFormat && q.answers[0].AnswerFormat === "FREETEXT") && q.answers ? (
                                            <div>

                                                <span className="textareaEdit" id="question" role="textbox" contentEditable autoComplete="on"
                                                    id={q.answers[0].AnswerId}
                                                    name={q.answers[0].AnswerId}
                                                    key={q.answers[0].AnswerId}
                                                    onInput={(e) => {

                                                        if (e.currentTarget.textContent && e.currentTarget.textContent.length > 0) {
                                                            e.currentTarget.classList.add("is-valid");
                                                            e.currentTarget.classList.add("form-control");
                                                        } else {
                                                            e.currentTarget.classList.remove("is-valid");
                                                            e.currentTarget.classList.remove("form-control");
                                                        }
                                                        q.answers[0].Answer = e.currentTarget.textContent;
                                                        q.AnswerValue = e.currentTarget.textContent;
                                                    }} >
                                                </span>
                                                {/* <span className="qa-label" id={"tellcaller" + q.QuestionId}>{q.TellCaller}</span> */}
                                                <HomeHyperlinks tellCaller={q.TellCaller} id={"tellcaller" + q.QuestionId}></HomeHyperlinks>
                                            </div>

                                        ) : (
                                            ""
                                        )}

                                        {q && q.answers && q.answers[0] && q.answers[0].AnswerFormat && q.answers[0].AnswerFormat === "DATE" && q.answers ? (

                                            <div>
                                                <Input className="LbBold"
                                                    type="date"
                                                    name="date"
                                                    id={q.answers[0].AnswerId}

                                                    placeholder=""
                                                    onChange={(e) => {
                                                        let val = e.target.value.toString();
                                                        q.answers[0].Answer = val;
                                                        q.AnswerValue = val;

                                                        if (val && val.toLowerCase() != "date") {
                                                            e.currentTarget.classList.add("is-valid");
                                                            e.currentTarget.classList.add("form-control");
                                                        } else {
                                                            e.currentTarget.classList.remove("is-valid");
                                                            e.currentTarget.classList.remove("form-control");
                                                        }


                                                    }}
                                                />
                                                {/* <span className="qa-label" id={"tellcaller" + q.QuestionId}>{q.TellCaller}</span> */}
                                                <HomeHyperlinks tellCaller={q.TellCaller} id={"tellcaller" + q.QuestionId}></HomeHyperlinks>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>











                                ) : (
                                    ""
                                )}


                            </FormGroup>
                        </Card>

                    ))}

                </div>


            ) : ("")}




        </>
    );
}
