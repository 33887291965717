/// <reference path="home-qa.jsx" />
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { AiOutlineFontColors } from "react-icons/ai";

export default function HomeHyperlink(props) {
  useEffect(() => {
    document.getElementById("sr_lang").setAttribute("langVal", props.lang);
  }, []);


  return (
    <>
      <div className="hyperlink">
        {!props.hideStatusQueryButton ? (
          <Button
            color="info"
            onClick={(e) => {
              props.changePage(props.isHome);
            }}
          >
            {props.translate(props.lang, "SRhyperlinkLabel")}
          </Button>
        ) : ("")}
        {" "}
        {props.useTranslation ? (
          <Button id="sr_lang"
            color="info"
            onClick={(e) => {
              props.changeLang(props.lang);
            }}
          >
            {props.translate(props.lang, "language")}
          </Button>
        ) : (
          ""
        )}
        {props.useFontOptions ? (
          <>

            <AiOutlineFontColors className="fontIcon textDecrease" onClick={(e) => {
              props.changeFont(16);
            }} />
            <AiOutlineFontColors className="fontIcon textDefault" onClick={(e) => {
              props.changeFont(17);
            }} />
            <AiOutlineFontColors className="fontIcon textIncrease" onClick={(e) => {
              props.changeFont(18);
            }} />

          </>
        ) : (
          ""
        )}

      </div>



    </>
  );
}