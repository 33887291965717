import React, { useEffect, useRef, useState } from "react";

export default function HomeHyperlinks(props) {
    const divRef = useRef(null);
    const [tellCaller, setTellCaller] = useState("");

    useEffect(() => {
        try {
            let caller = (props.tellCaller) ? props.tellCaller : "";
            let hyperlinks = props.tellCaller.match(/((http:\/\/|https:\/\/|www.|\/\/|file:|ftp:)[^\s]+)/gi);           
            if (hyperlinks) {
                [... new Set(hyperlinks)].forEach(h => {  
                    caller = caller.replace((new RegExp(String.raw`${h}`, "g")), ` <a href="${h}" target="_blank">${h}</a> `);
                });

            }
            divRef.current.innerHTML = caller;
        }
        catch (e) { console.log(e); }
    }, [props.tellCaller]);


    return (
        <>
            <span ref={divRef} className="qa-label" ></span>
        </>)
};