import React, { useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import PopoverItem from "../common/PRpopover";

const PRInput = (props) => {
  useEffect(() => {
  }, []);
  return (
    <>
      {props.elem.disable ? (
        ""
      ) : (
        <FormGroup check>
          <Label check>
            <Input
                name={props.elem.name}
                id={props.elem.name}
              type="checkbox"
              onClick={(e) => {
                props.validate(e);
              }}
                              onKeyUp={(e) => {
                                  props.validate(e);
                              }}
              onChange={(e) => {
                props.validate(e);
              }}
            />{" "}
            {props.translate(props.lang, props.elem.label)}
                      </Label>
                      <PopoverItem elem={props.elem} translate={props.translate} lang={props.lang} />
        </FormGroup>
      )}
    </>
  );
};

export default PRInput;
